<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Content from '@/services/Content'
    import Swal from "sweetalert2";
    import modalAddBusinessNews from "@/components/modals/business/modalAddBusinessNews";
    import modalEditBusinessNews from "@/components/modals/business/modalEditBusinessNews";

    export default {
        components: { Layout, PageHeader, modalAddBusinessNews,modalEditBusinessNews},
        page: {
            title: "Business News",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        data() {
            return {
                title: "News Business",
                items: [
                    {
                        text: "News",
                    },
                    {
                        text: "News Business",
                        active: true,
                        href: "/news/feed",
                    },
                ],
                showRefresh:true,
                showAlert: false,
                isBusyButton: false,
                isBusy: false,
                error: null,
                showModal:false,
                modalData:{},
                link_id:null,
                tableData: [],
                totalRows: 0,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "published",
                sortDesc: true,
                fields: [
                    {
                        key: "title",
                        label: "Title",
                        sortable: true,
                    },
                    {
                        key: "image",
                        label: "Image",
                        sortable: false,
                    },
                    {
                      key: "link",
                      label: "Link",
                      sortable: false,
                    },
                    {
                        key: "published",
                        label: "Published Date",
                        sortable: true,
                    },
                    {
                        key: "active",
                        label: "Status",
                        sortable: true,
                    },
                    "action",
                ],
                 "refreshNewsBtn":false
            };
        },
        async created() {
            await this.getNews()
            this.refreshNewsBtn = await this.checkObjectAccessCall('button.news.refresh');
        },
        methods: {

            modalAddBusinessNews() {
                this.$bvModal.show("add_business_news");
            },
            callModalBusinessNewsEdit(data) {
                this.$bvModal.show("news_edit");
                this.modalData = data;
                console.log("Opening modal with data:", data);
            },
            


            async getNews(){
                try {
                    this.toggleBusy();
                    const response = await Content.getBusinessNews(1000,'all','news')
                    this.tableData = response.data.news
                    this.totalRows = this.tableData.length
                    this.toggleBusy();
                } catch (error) {
                    this.toggleBusy();
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.tableData = []
                    this.totalRows = 0;
                }
            },

            removeNews(id) {
              Swal.fire({
                  title: "Deactivate news business?",
                  text: "This news business will not be active ! You can activate later again !",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#34c38f",
                  cancelButtonColor: "#f46a6a",
                  confirmButtonText: "Disable!",
              }).then(async (result) => {
                  if (result.value) {
                      try {
                          const response = await Content.deleteBusinessNews(id);
                          if (response.data) {
                              const newsItem = this.tableData.find((item) => item.id === id);
                              if (newsItem) {
                                  newsItem.active = 0;
                              }
                              Swal.fire("Remove Business News", "Business News is successfully removed!", "success");
                          } else {
                              const error = response.data.error || "Failed";
                              Swal.fire("Fail!", error, "warning");
                          }
                      } catch (error) {
                          this.error = error.response?.data?.error || "An error occurred";
                          Swal.fire("Error", this.error, "error");
                      }
                  }
              });
          },

            /**
             * Search the table data with search input
             */
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },

        },
        middleware: "authentication",
    };
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                       <div class="row mb-0">
                            <div  class="col-12">
                                <a href="javascript:void(0);" :disabled=isBusyButton class="btn btn-success waves-effect waves-light text-end" @click="modalAddBusinessNews()">
                                    <span v-if="!isBusyButton"><i class="mdi mdi-plus me-2"></i>Add Business News</span>
                                    <span v-else>Loading...</span>
                                </a>
                            </div>
                        </div>
                        <h4 class="card-title">Total News: {{totalRows}}</h4>
                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select
                                                v-model="perPage"
                                                size="sm"
                                                :options="pageOptions"
                                        ></b-form-select
                                        >&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div
                                        id="tickets-table_filter"
                                        class="dataTables_filter text-md-end"
                                >
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ms-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                    :busy="isBusy"
                                    :items="tableData"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    show-empty
                                    empty-text="No Data Found"
                                    @filtered="onFiltered"
                            >

                                <template v-slot:cell(image)="data">
                                    <div class="flex-shrink-0">
                                        <img class="img-fluid table-images" :src="data.item.image">
                                    </div>
                                </template>
                                <template v-slot:cell(active)="data">
                                  <span :class="{'text-success': data.item.active === 1, 'text-danger': data.item.active === 0}">
                                    {{ data.item.active === 1 ? 'Active' : 'Inactive' }}
                                  </span>
                                </template>
                                <template v-slot:cell(action)="data">
                                  <ul class="list-inline mb-0">
<!--                                    <li class="list-inline-item" v-if="data.item.active === 1">-->
<!--                                      <a-->
<!--                                        href="javascript:void(0);"-->
<!--                                        class="px-2 text-danger"-->
<!--                                        v-b-tooltip.hover-->
<!--                                        title="Disable Business News"-->
<!--                                        @click="removeNews(data.item.id)"-->
<!--                                      >-->
<!--                                        <i class="uil uil-trash-alt font-size-18"></i>-->
<!--                                      </a>-->
<!--                                    </li>-->
                                    <li class="list-inline-item" >
                                      <a
                                        href="javascript:void(0);"
                                        class="px-2 text-primary"
                                        v-b-tooltip.hover
                                        title="Edit Business News"
                                        @click="callModalBusinessNewsEdit(data.item)"
                                      >
                                        <i class="uil uil-pen font-size-18"></i>
                                      </a>
                                    </li>
                                  </ul>
                                </template>
                                 <template #table-busy>
                                                <div class="text-center text-danger my-2">
                                                     <b-spinner class="align-middle"></b-spinner>
                                        <strong>Loading...</strong>
                                    </div>
                                </template>

                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-end">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination
                                                v-model="currentPage"
                                                :total-rows="totalRows"
                                                :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

   <!-- MODALS -->
      <modalAddBusinessNews  @refreshScreen="getNews"></modalAddBusinessNews>
      <modalEditBusinessNews :data="modalData"  @onRefresh="getNews">
      </modalEditBusinessNews>
      <!-- END  MODALS -->
    </Layout>
</template>