<script>
    import Content from '@/services/Content'
    import validationMessages from '@/components/validations'
    import { required } from "vuelidate/lib/validators";
    import CKEditor from '@ckeditor/ckeditor5-vue2';
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    import flatPickr from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";

    export default {
        components:{
            validationMessages,   ckeditor: CKEditor.component, flatPickr,
        },
        data() {
            return {
                csrf_token: localStorage.getItem('csrf_token'),
                  showModal: false,
                  tryingToSubmit: false,
                  submitted: false,
                  title: "",
                  content: "",
                  image: "",
                  published: "",
                  file:null,
                  content_type: "news",
                  image_removed: false,
                  editor: ClassicEditor,
                  editorData: '',
                  datetimeconfig: {
                      enableTime: true,
                      time_24hr: true,
                      minuteIncrement: 1,
                      inline: true,
                  },
                  verified_checked: true,
            };
        },
        validations: {
            title: { required },
            content: { required },
            published: { required }, 
        },
        methods: {
            refreshBusinessData() { 
                this.$emit('refreshScreen') //event from parent
            },
      
            addBusinessNews() {
                this.tryingToSubmit = true;
                this.submitted = true;
                this.$v.$touch();

                if (this.$v.$invalid) {
                    this.tryingToSubmit = false;
                    return;
                }

                const formData = new FormData();
                formData.append("title", this.title);
                formData.append("link", this.link);
                formData.append("content", this.content);
                formData.append("image", this.file);
                formData.append("published",this.published);
                formData.append("csrf_token", this.csrf_token);
                formData.append("content_type", this.content_type);
                formData.append("active", this.verified_checked == true ? 1 : 0);

                Content.addBusinessNews(formData)
                  .then(() => {
                    this.successmsg("Business News Created!");
                    this.closeModal();
                    this.refreshBusinessData();
                  })
                  .catch((error) => {
                    const errorMsg = error.response?.data?.error || "Failed to create business news";
                    this.failedmsg(errorMsg);
                  })
                  .finally(() => {
                    this.tryingToSubmit = false;
                  });
            },
            closeModal() {
                this.showModal = false;
            },
          
            selectFile(event) {
              if (this.image) {
                URL.revokeObjectURL(this.image);
              }
              const file = event.target.files[0];
              this.file = file;
              this.image = URL.createObjectURL(file);
            },
            removeImage() {
              this.file = null;
              this.image = "";
              this.$refs['file-input'].reset()
              this.image_removed = true
            },

            resetProps() {
             this.submitted = false;
             this.image = '';
             if (this.image) URL.revokeObjectURL(this.image);
             this.title = '';
             this.content = '';
             this.published = '';
             this.file = null;
             this.verified_checked = true;
          },
        }
    };
</script>

<template>
    <b-modal id="add_business_news" size="lg" v-model="showModal" @hidden="resetProps" title="Add Business News" title-class="font-18">
    <form @submit.prevent="addBusinessNews">
        <div class="row">
          <div class="col-md-6">

            <b-form-checkbox-group>
              <div class="form-check form-switch form-switch-lg mb-3" >
                <label class="form-check-label" for="newsStatus">Active</label>
                <input v-model="verified_checked"
                       type="checkbox"
                       class="form-check-input"
                       id="newsStatus"
                />
              </div>
            </b-form-checkbox-group>

            <b-form-group label="Title" label-for="title" class="mb-3">
               <b-form-input v-model="title"></b-form-input>
               <validationMessages 
                v-if="submitted"
                :fieldName="'Title'"
                :validationName="$v.title">
              </validationMessages>
            </b-form-group>

            <b-form-group label="Link" label-for="title" class="mb-3">
              <b-form-input v-model="link"></b-form-input>
              <validationMessages
                  v-if="submitted"
                  :fieldName="'Link'"
                  :validationName="$v.link">
              </validationMessages>
            </b-form-group>

            <b-form-group label="Upload Image" label-for="image" class="mb-3 image-area">
               <img :src="image != '' ? image : require('@/assets/images/image_not_found.png')" alt="" width="150" class="img-thumbnail me-2" >
               <b-form-file @change="selectFile" ref="file-input" type="file" class="mt-3" plain accept="image/jpeg, image/png" ></b-form-file>
               <a v-if="image" class="remove-image" href="javascript:void(0);" @click="removeImage" style="display: inline;">&#215;</a>
            </b-form-group>

            <b-form-group label="Content" label-for="content" class="mb-3">
                <template>
                    <ckeditor :editor="editor" v-model="content"></ckeditor>
                </template>
                <validationMessages 
                v-if="submitted"
                :fieldName="'Content'"
                :validationName="$v.content">
               </validationMessages>
            </b-form-group>

</div>
<div class="col-md-6">
            <b-form-group label="Published Date" label-for="published" class="mb-3">
            <flat-pickr
              v-model="published"
              :config="datetimeconfig"
              class="form-control mb-2"
              name="published"
            />
            <validationMessages
              v-if="submitted"
              :fieldName="'Published'"
              :validationName="$v.published"
            ></validationMessages>
          </b-form-group>
          </div>
        </div>
        <input type="hidden" name="content_type" v-model="content_type">
        <input type="hidden" name="csrf_token" v-model="csrf_token">
      </form>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="addBusinessNews" :disabled="tryingToSubmit">
                <b-spinner v-show="tryingToSubmit" small></b-spinner>
                Submit</b-button>
        </template>

    </b-modal>
</template>